import { create } from 'zustand';
import { EAdvertiserType } from 'modules/api/clients/advertisement/detail/enums/advertiser/EAdvertiserType';
import { EMainCategory } from 'modules/api/clients/advertisement/common/enums/parameters/category/EMainCategory';

export const formTextInitialMoreInfo = 'Dobrý deň, kontaktujte ma, prosím, ohľadom informácií o ponúkaných nehnuteľnostiach.';
export const formTextInitialInspection = 'Dobrý deň, mám záujem o obhliadku ponúkanej nehnuteľnosti.';
interface IPageViewAdvertisementDetailState {
    mainCategoryValue: EMainCategory;
    advertiserType: EAdvertiserType;
}

type AdvertisementStore = {
    advertiserFormTextState: string;
    setAdvertiserFormTextState: (text: string) => void;

    pageViewAdvertisementDetailState: IPageViewAdvertisementDetailState;
    setPageViewAdvertisementDetailState: (detailState: IPageViewAdvertisementDetailState) => void;

    listingAdvertisementIds: string[];
    setListingAdvertisementIds: (ids: string[]) => void;
};

export const useAdvertisementStore = create<AdvertisementStore>((set) => ({
    advertiserFormTextState: 'Dobrý deň, kontaktujte ma, prosím, ohľadom informácií o ponúkaných nehnuteľnostiach.',
    setAdvertiserFormTextState: (text) => set(() => ({
        advertiserFormTextState: text,
    })),

    pageViewAdvertisementDetailState: null,
    setPageViewAdvertisementDetailState: (detailState) => set(() => ({
        pageViewAdvertisementDetailState: detailState,
    })),

    listingAdvertisementIds: [],
    setListingAdvertisementIds: (ids) => set(() => ({
        listingAdvertisementIds: ids,
    })),
}));