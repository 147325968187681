export const getSegmentParams = (segmentParams: string | string[]): Record<string, string> => {
    if (!segmentParams || !Array.isArray(segmentParams)) return {};

    const isGallerySubpage = Array.isArray(segmentParams)
        && segmentParams.length > 2;

    const group = isGallerySubpage
        ? segmentParams[0]
        : undefined;

    const id = isGallerySubpage
        ? segmentParams[1]
        : segmentParams[0];

    const slug = isGallerySubpage
        ? segmentParams[2]
        : segmentParams[1];

    return {
        id,
        slug,
        group,
    };
};
