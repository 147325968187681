import { create } from 'zustand';
import {
    IFacebookPixelTracker,
    IGemiusTracker,
    IGtmTracker,
    ILivemonitorTracker,
    IRtbHouseTracker,
} from 'modules/analytics/IAnalytics';

type LayoutStore = {
    analyticsGtmTrackerState: IGtmTracker;
    setGtmTracker: (tracker: IGtmTracker) => void;

    analyticsRtbHouseTrackerState: IRtbHouseTracker;
    setRtbHouseTracker: (tracker: IRtbHouseTracker) => void;

    analyticsLivemonitorTrackerState: ILivemonitorTracker;
    setLivemonitorTracker: (tracker: ILivemonitorTracker) => void;

    analyticsFacebookPixelTrackerState: IFacebookPixelTracker;
    setFacebookPixelTracker: (tracker: IFacebookPixelTracker) => void;

    analyticsGemiusTrackerState: IGemiusTracker;
    setGemiusTracker: (tracker: IGemiusTracker) => void;
};

export const useAnalyticsStore = create<LayoutStore>((set) => ({
    analyticsGtmTrackerState: null,
    setGtmTracker: (tracker) => set(() => ({
        analyticsGtmTrackerState: tracker,
    })),

    analyticsRtbHouseTrackerState: null,
    setRtbHouseTracker: (tracker) => set(() => ({
        analyticsRtbHouseTrackerState: tracker,
    })),

    analyticsLivemonitorTrackerState: null,
    setLivemonitorTracker: (tracker) => set(() => ({
        analyticsLivemonitorTrackerState: tracker,
    })),

    analyticsFacebookPixelTrackerState: null,
    setFacebookPixelTracker: (tracker) => set(() => ({
        analyticsFacebookPixelTrackerState: tracker,
    })),

    analyticsGemiusTrackerState: null,
    setGemiusTracker: (tracker) => set(() => ({
        analyticsGemiusTrackerState: tracker,
    })),
}));